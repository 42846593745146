@font-face {
  font-family: 'umbasoft-altbold';
  src: url('/_fonts/umbasoft-altbold-webfont.woff2') format('woff2'),
       url('/_fonts/umbasoft-altbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: umbasoft-altbold, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.offscreen {
  position: absolute!important;
    clip: rect(1px 1px 1px 1px)!important;
    clip: rect(1px, 1px, 1px, 1px)!important;
    clip-path: inset(50%)!important;
    padding: 0!important;
    border: 0!important;
    height: 1px!important; 
    width: 1px!important; 
    overflow: hidden!important;
    white-space: nowrap!important;
}
h1, h2, h3, h4, h5, h6, th, tr, td {
  margin: 0;
  padding: 0;
  font-weight: normal;
}