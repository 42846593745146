body {
  background: #fbf8ed;
  display: flex;
} 

#root {
 border: 3px solid #525048;
 background-color: #FBFBF9;
 border-radius: 10px;
 flex-grow: 1;
 margin: 20px;
 padding: 20px;
}

a.breadfriend, a.breadfriend:visited, a.breadfriend:focus, a.breadfriend:hover, a.breadfriend:active {
  font-size: 2em;
  text-decoration: none;
  color: #825e10;
}

a.breadfriend img {
  width: 50px;
  padding-right: 5px;
}

li  {
 list-style-type: none;
}

ul.deck {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

li.card {
  background: white;
  border: 3px lightgray solid;
  border-radius: 10px;
  transition: border-color 0.8s ease;
  padding: 7px;
  width: 200px;
  margin: 7px;
  text-align: center;
}

li.card:hover {
  border: 3px #ca8b31 solid;
}

li.card img {
  width: 200px;
  object-fit: contain;
}

li.card a, li.card a:visited, li.card a:hover, li.card a:focus, li.card a:active {
  font-size: 1.5em;
  text-decoration: none;
  color: #825e10;
}

.details {
  font-family: sans-serif;
  background: white;
  border: 3px lightgray solid;
  border-radius: 10px;
  font-size: 1.5em;
  color: #525048;
  padding: 7px;
  margin: 10px auto;
  max-width: 600px;
}

.details img {
  width: 100%;
  object-fit: contain; 
  max-height: 300px;
  display: block;
}

.details .h1 {
  font-family: 'umbasoft-altbold';
  font-size: 1.5em;
}

.details .h2 { 
  font-size: .8em;
}
.details .storeName {
  color: #E41720;
  font-weight: bold;
  margin-bottom: 5px;
}

.details .store {
  margin: 20px 0;
}

.details table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

.details th {
  font-size: .7em;
  border-bottom: 1px solid black;
  text-align: left;
}

.details td, .details th{
  padding: 5px;
  margin: 5px;
  
}

.details .lowest {
  width: 15px;
  height: 15px;
  background: blue;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-left: 5px;
}

.details .downArrow {
  color: white;
  font-size: .5em;
  position: relative;
  top: -60%;
  left: 20%;
  font-weight: bold;
}

.details .hide {
  display:none;
}